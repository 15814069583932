<template>
  <van-action-sheet v-model="isShow" title="使用消费金">
    <div class="content">
      <div class="layout">
        <span>当前消费金</span>
        <span>￥199.00</span>
      </div>
      <div class="layout xiao-fei-jin">
        <span>本次最多可用消费金</span>
        <span>￥10.00</span>
      </div>
      <div class="layout">
        <span>使用消费金</span>
        <div>￥<input type="text" class="input" /></div>
      </div>
      <van-button
        block
        round
        color="linear-gradient(to right, #F25757 , #ED301D)"
        class="button-position"
        >
        确认使用
        </van-button
      >
    </div>
  </van-action-sheet>
</template>

<script>
export default {
  name: '',
  props: {
    isShow: {
      type: Boolean
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 16px 16px 160px;
}
.layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.xiao-fei-jin {
  margin: 12px 0;
}
.input {
  width: 78px;
  height: 28px;
}
.content[data-v-36830e4c]{
  padding: 16px 16px 0px 16px;
}
.button-position{
  margin: 144px 0 10px 0;
}
</style>
