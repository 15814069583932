<template>
  <div class="card-layout">
    <div class="card-header">
      <van-icon name="shop-o" color="#9b9b9b" />
      <div class="title">供应商：钜宏自营店铺</div>
    </div>
    <!-- 商品详情 -->
    <OrderDetails></OrderDetails>
    <div class="operation">
      <div class="number-of">
        <span>购买数量</span>
        <van-stepper v-model="numberOf" />
      </div>
      <van-cell-group>
        <van-field v-model="value" label="配送方式" readonly />
      </van-cell-group>
      <van-field
        v-model="message"
        rows="2"
        autosize
        label="留言"
        type="textarea"
        maxlength="50"
        placeholder="请输入留言"
        show-word-limit
      />
    </div>
    <!-- 总价格 -->
    <div class="summary-price">
      <div class="sum-up-price layout">
        <span>商品总价</span>
        <span>￥199.00</span>
      </div>
      <div class="send-price layout">
        <span>配送费用</span>
        <span class="color">￥0.00</span>
      </div>
      <div class="spend-gold" @click="onClick">
        <div>
          <span>消费金</span>
          <span>（本次最多抵扣￥10）</span>
        </div>
        <div>
          <div class="spend-gold-color">-￥10.00</div>
          <van-icon name="arrow" class="color" />
        </div>
      </div>
      <ActionSheet :isShow="isShow"></ActionSheet>
      <div class="sum-price layout">
        <span>合计</span>
        <span class="color">￥199.00</span>
      </div>
    </div>
  </div>
</template>

<script>
import OrderDetails from './order-details'
import ActionSheet from './action-sheet'
export default {
  name: 'order-card',
  components: {
    OrderDetails,
    ActionSheet
  },
  data () {
    return {
      value: '普通快递',
      isShow: false,
      message: '',
      numberOf: 0
    }
  },
  methods: {
    onClick () {
      this.isShow = !this.isShow
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 16px 16px 160px;
}
.card-layout {
  padding: 12px;
  margin-top: 12px;
  border-radius: 15px;
  background-color: #fff;
}
.card-header {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  .title {
    margin-left: 4px;
  }
}
/deep/.operation {
  .number-of {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .van-cell {
    padding: 6px 0;
    .van-field__label {
      font-size: 18px;
      font-weight: 600;
      color: #000;
    }
  }
}
// 总价格
.layout {
  display: flex;
  justify-content: space-between;
  padding: 6px 16px 6px 0;
}
.send-price {
  .color {
    color: #6f6f6f;
  }
}
.spend-gold {
  display: flex;
  justify-content: space-between;
  padding: 6px 0 6px 0;
  div {
    display: flex;
    align-items: center;
  }
  align-items: center;
  .spend-gold-color {
    color: #fd6d1f;
  }
}
.sum-price{
  .color {
    color: #ED301D;
  }
}
</style>
