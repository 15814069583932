<template>
  <div>
    <div class="order-ok">
      <van-icon name="arrow-left" />
      <div class="order-ok-text">确认订单</div>
    </div>
    <!-- 地址选择 -->
    <div>
      <AddressNav></AddressNav>
    </div>
    <!-- 订单详情 -->
    <div>
      <OrderCard></OrderCard>
    </div>
    <!-- 底部提交订单 -->
    <van-submit-bar :price="3050" button-text="提交订单" @submit="isPay = true">
      <van-checkbox v-model="checked">全选</van-checkbox>
      <!-- <template #tip v-if="false">
        你的收货地址不支持同城送
        <span @click="onClickEditAddress">点击修改地址</span>
      </template> -->
    </van-submit-bar>
    <!-- 支付弹出层 -->
    <van-popup v-model="isPay" round closeable position="bottom">
      <div class="pay-details-layout">
        <div class="pay-details-title">支付详情</div>
        <div class="shop-info">
          <span class="shop-title">钜宏家庭短号</span>
          <div>￥189.00</div>
        </div>
        <div class="pay-info">
          <div class="lines">
            <span class="now-lines">当前余额</span>
            <span>￥200.00</span>
            <van-icon name="arrow" class="right-btn-icon" />
          </div>
          <div class="lines-pay">
            <span>余额支付</span>
            <div>
              <span>￥</span>
              <input type="text" class="fillIn-the-input" />
            </div>
          </div>
          <van-radio-group v-model="radio">
            <van-cell-group :border="false">
              <van-cell clickable @click="radio = '1'">
                <template #title>
                  <img
                    class="wechat-icon"
                    width="20"
                    height="20"
                    src="./images/wechat_icon@2x.png"
                  />
                  <span class="custom-title">微信支付</span>
                </template>
                <template #right-icon>
                  <van-radio name="1" />
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
          <div class="button-ok">
            <van-button
              color="linear-gradient(to right, #ff6034, #ee0a24)"
              block
              round
              @click="showPopup = true"
            >
              确认支付
            </van-button>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 密码弹出层 -->
    <van-popup
      v-model="showPopup"
      :closeable="true"
      close-icon-position="top-left"
    >
      <div class="password-layout">
        <div class="enter-password">
          <div class="password-title">请输入余额支付密码</div>
          <div class="pay-price">￥189.00</div>
        </div>
        <!-- 密码输入框 -->
        <van-password-input
          :value="value"
          :focused="isFocus"
          @focus="onFocus"
        />
      </div>
    </van-popup>
    <van-number-keyboard
      z-index="999999"
      :show="show"
      theme="custom"
      extra-key="."
      close-button-text="完成"
      @blur="show = false"
    />
  </div>
</template>

<script>
import AddressNav from './moudles/address-nav'
import OrderCard from './moudles/order-card'
export default {
  name: 'submit-order',
  components: {
    AddressNav,
    OrderCard
  },
  data () {
    return {
      checked: true,
      isPay: false,
      showPopup: false,
      radio: '1',
      value: '',
      showKeyboard: true,
      show: false,
      isFocus: true
    }
  },
  methods: {
    onFocus () {
      if (this.isFocus) {
        this.showKeyboard = true
        this.show = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.order-ok {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 58px;
  font-size: 22px;
  padding: 0 12px;
  background-color: #fff;
  .order-ok-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.pay-details-layout {
  padding: 12px 12px 12px 12px;
  .pay-details-title {
    text-align: center;
    font-size: 20px;
  }
}
.van-cell__title {
  display: flex;
  align-items: center;
}
.wechat-icon {
  margin-right: 10px;
}
.shop-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  .shop-title {
    font-size: 14px;
    color: #6f6f6f;
  }
  div {
    font-size: 30px;
    font-weight: 600;
  }
}
.pay-info {
  .lines {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 6px 24px 6px 0;
    .right-btn-icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
  .lines-pay {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0px 6px 0;
    .fillIn-the-input {
      width: 78px;
      height: 28px;
    }
  }
}
.button-ok {
  margin-top: 180px;
}
// 密码弹出层
.password-layout {
  padding: 12px;
}
.enter-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  .password-title {
    color: #6f6f6f;
    font-size: 22px;
  }
  .pay-price {
    font-size: 30px;
    font-weight: 600;
    margin: 20px 0 40px 0;
  }
}
</style>
