<template>
  <div>
    <!-- 订单详情 -->
    <div class="order-details">
      <div class="img-box">
        <img class="commodity-img" src="../images/img-30@2x.png" alt="" />
      </div>
      <div class="commodity-info">
        <div class="title-info">
          <span>女长袖职业正装2018新款衬衣</span>
          <span>￥199.00</span>
        </div>
        <div class="specification">
          <div class="specification-info">
            <span>颜色：蓝色</span>
            <span>尺码：S</span>
            <span class="tag">免运费</span>
          </div>
          <div class="number-of">x1</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'order-details',
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
// 商品详情
.order-details {
  padding: 14px 0;
  display: flex;
  justify-content: start;
  font-size: 16px;
  .img-box {
    width: 110px;
    flex-shrink: 0;
    .commodity-img {
      width: 100%;
    }
  }
}
.commodity-info {
  margin-left: 10px;
  .title-info {
    display: flex;
  }
  .specification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #aaaaaa;
    .specification-info {
      display: flex;
      flex-direction: column;
      justify-content: start;
    }
  }
}
.tag {
  text-align: center;
  border: 1px solid #da432e;
  color: #da432e;
  border-radius: 20px;
}
</style>
