<template>
  <div>
    <div class="address-layout" @click="onClick">
      <div class="contact" v-if="true">
        <span class="name">请选择您的地址</span>
      </div>
      <div class="contact" v-else>
        <span class="name">王晓燕</span>
        <span>18883354199</span>
      </div>
      <div class="address-details">
        <van-icon name="location-o" size="20" />
        <div class="address" v-if="false">重庆市渝北区回兴街道金源路1号国雅彩云间9栋4-5</div>
        <div class="address address-no" v-else>请选择你的收货地址</div>
        <van-icon name="arrow" size="20" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'address-nav',
  data () {
    return {}
  },
  methods: {
    onClick () {
      this.$router.push('/address-choose')
    }
  }
}
</script>

<style lang="less" scoped>
.address-layout {
  padding: 70px 12px 10px 12px;
  background-color: #fff;
  border-radius: 0 0px 15px 15px;
  font-size: 18px;
  .contact {
    padding: 0 0 0 38px;
    font-weight: 600;
    .name {
      margin-right: 10px;
    }
  }
  .address-details {
    display: flex;
    justify-content: start;
    align-items: center;
    color: #6f6f6f;
    font-size: 16px;
    .address {
      padding: 0 18px;
    }
    .address-no {
      margin-right: 170px;
    }
  }
}
</style>
